import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";

import api from '../../utils/webcall';
import AccessController from '../../components/access_controller';
import UserProfile from '../../components/User';
import { GreenTickIcon, RedCrossIcon } from '../../components/commons/icons';

function ViewBillsList(props) {
    const { showErrorMessage, showSuccessMessage } = props;
    const [billListData, setBillListData] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const username = UserProfile.getName();

    useEffect(()=>{
        api.get(`/pos/bill/list/`).then((res) => {
            let data = res.data;
            setBillListData(data['bills']);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            showErrorMessage(String(error));
        });
    }, [])

    return (
        <AccessController
            onlyAdmin={false}
        >
            <Grid
                container
                direction={'column'}
                rowSpacing={2}
                alignItems="center"
            >
                <Grid item>
                    <h2>DIVASH | ADMIN</h2>
                </Grid>
                <Grid item>
                    <i>Welcome {username}</i>.
                </Grid>
                <Grid item>
                    <b>View Bill.</b>
                </Grid>
                <Grid item>
                    {loading ? <Grid item><CircularProgress /></Grid> : <></>}
                    {!loading ? (<TableContainer
                        component={Paper}
                        style={{
                            overflowX: "initial"
                        }}
                        key="inventory-table-container"
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Bill #</TableCell>
                                    <TableCell align="center">Approved</TableCell>
                                    <TableCell align="center">Bill Submitted On</TableCell>
                                    <TableCell align="center">Total Amount</TableCell>
                                    <TableCell align="center">Number of Items</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {billListData.map((item, index) => {
                                    return (
                                        <TableRow>
                                            <TableCell align="center">{item["id"]}</TableCell>
                                            <TableCell align="center">{item["approved"] ? <GreenTickIcon /> : <RedCrossIcon />}</TableCell>
                                            <TableCell align="center">{item["submitted_date"]}</TableCell>
                                            <TableCell align="center">{item["total_amount"]} {item["currency"]}</TableCell>
                                            <TableCell align="center">{item["number_of_items"]}</TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant='outlined'
                                                    onClick={(e) => {
                                                        navigate(`/bill/view/${item['id']}`)
                                                    }}
                                                >
                                                    ...
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>) : <></>}
                </Grid>
            </Grid>
        </AccessController >
    )
}

export default ViewBillsList;