import _ from 'lodash';
import React, { useState, useEffect } from "react";
import { jwtDecode } from 'jwt-decode';

import api from '../../utils/webcall';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../utils/token';

var UserProfile = (function () {
    var username = "username";
    var userdata = 'userdata';
    var auth_key = "cur_user_auth";
    var isAuthorized = 'is_authorized'

    var setIsAuthorized = (flag) => {
        window.sessionStorage.setItem(isAuthorized, flag);
    }

    var userDetailsAvailable = () => {
        return isLoggedIn() && (window.sessionStorage.get(userdata) != undefined || window.sessionStorage.get(userdata) != null)
    }

    var auth = async () => {
        try{
            const token = window.sessionStorage.getItem(ACCESS_TOKEN);
            if (token) {
                const decoded = jwtDecode(token);
                const tokenExpiration = decoded.exp;
                const now = Date.now() / 1000;

                if (tokenExpiration < now) {
                    await refreshToken();
                } else {
                    setIsAuthorized(true);
                }
            }
            else {
                setIsAuthorized(false);
            }
        }
        catch{
            setIsAuthorized(false);
        }
    };

    var refreshToken = async () => {
        const refreshToken = window.sessionStorage.getItem(REFRESH_TOKEN);
        try {
            const res = await api.post('/api/token/refresh/', {
                refresh: refreshToken,
            });
            if (res.status === 200) {
                window.sessionStorage.setItem(ACCESS_TOKEN, res.data.access);
                setIsAuthorized(true);
            } else {
                setIsAuthorized(false);
            }
        } catch (error) {
            console.error('Error refreshing token', error);
            setIsAuthorized(false);
        }
    };

    const logout = () => {
        window.sessionStorage.removeItem(ACCESS_TOKEN);
        window.sessionStorage.removeItem(userdata);
        setIsAuthorized(false);
        window.location.reload();
    };
    
    var getName = function () {
        if(isLoggedIn()){
            return JSON.parse(window.sessionStorage.getItem(userdata))["first_name"];
        }
        return "";
        
    };

    var setName = function (name) {
        let newname = _.camelCase(name);
        window.sessionStorage.setItem(username, newname);
    };

    var setAuth = function(token, refresh){
        window.sessionStorage.setItem(ACCESS_TOKEN, token);
        window.sessionStorage.setItem(REFRESH_TOKEN, refresh);
    }

    var setUserData = function(data){
        window.sessionStorage.setItem(userdata, JSON.stringify(data));
    }

    var isLoggedIn = function(){
        let temp = window.sessionStorage.getItem(ACCESS_TOKEN);
        return !(temp === null || temp === undefined);
    }

    var getRole = function(){
        if(!isLoggedIn()){
            return '';
        }
        let temp = JSON.parse(window.sessionStorage.getItem(userdata))['role'];
        return (temp != null && temp != undefined) ? temp.toUpperCase() : '';
    }

    var isManager = function(){
        return ((getRole() === "MANAGER") || (getRole() === "SUPERUSER"));
    }

    return {
        getName: getName,
        setAuth: setAuth,
        setUserData: setUserData,
        isLoggedIn: isLoggedIn,
        isManager: isManager,
        getRole: getRole,
        isAuthorized: isAuthorized,
        logout: logout
    }

})();

export default UserProfile;