import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";


import './css/login_page.css';
import api from '../../utils/webcall';
import UserProfile from '../../components/User';
import { Paper } from '@mui/material';

function LoginPage(props) {
    const { showErrorMessage, showSuccessMessage } = props;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword,] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (UserProfile.isLoggedIn()) {
            navigate("/home");
        }
    }, []);

    const hanldeLoginButtonClick = (e) => {
        e.preventDefault();
        setLoading(true);
        api.post(`/auth/login/`, {username, password}).then((res) => {
            if (res.status === 200) {
                let data = res.data;
                UserProfile.setAuth(data['access'], data['refresh']);
                api.get(`/auth/user/details/`, {}).then((res) => {
                    if(res.status=== 200){
                        let data = res.data;
                        UserProfile.setUserData(data);
                        console.log("Navigating to /home");
                        navigate('/home');
                    }
                    else {
                        showErrorMessage(res.status + " " + res.message);
                    }
                }).catch((error) => {
                    showErrorMessage(error);
                });
            }
            else {
                showErrorMessage(res.status + " " + res.message);
            }
        }).catch((error) => {
            console.log("Error in Login");
            console.log(error);
            if ((('response' in error) && ('status' in error['response'])) && error['response']['status'] === 401) {
                setError('Invalid Credentials');
                showErrorMessage(error.message);
            }
            else {
                setError(error['message']);
                showErrorMessage(error['message']);
                // showErrorMessage(String(error));
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Paper 
            elevation={2}
            sx={{textAlign:'center'}}
        >
            <h2>Store Management</h2>
            <form onFormSubmit={(e) => {
                hanldeLoginButtonClick(e);
            }}>
                <Grid
                    container
                    direction={'column'}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item>
                            <label>Username</label>
                        </Grid>
                        <Grid item>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter your username"
                                autoComplete='on'
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2} sx={{
                            marginTop: 2,
                            marginBottom: 2
                        }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        </Grid>
                        <Grid item>
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            // endAdornment={
                            //     <InputAdornment position="end">
                            //         <IconButton
                            //             aria-label={
                            //                 showPassword ? 'hide the password' : 'display the password'
                            //             }
                            //             onClick={handleClickShowPassword}
                            //             onMouseDown={handleMouseDownPassword}
                            //             onMouseUp={handleMouseUpPassword}
                            //             edge="end"
                            //         >
                            //             {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                            //         </IconButton>
                            //     </InputAdornment>
                            // }
                            />
                        </Grid>
                    </Grid>
                    {error && <div className="error">{error}</div>}
                    <Button variant="contained" disabled={loading} onClick={(e) => hanldeLoginButtonClick(e)}>
                        {loading ? <CircularProgress /> : <span>Login</span>}
                    </Button>
                    <br/>
                </Grid>
            </form>
        </Paper>
    );
}

export default LoginPage;
