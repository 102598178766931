import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import AccessController from "../../components/access_controller";
import UserProfile from "../../components/User";
import { VisuallyHiddenInput } from "../../utils/utils";
import api from "../../utils/webcall";


function OrderUploadForm(props) {
    const { showErrorMessage, showSuccessMessage } = props;
    const [selectedFiles, setSelectedFiles] = useState(null);

    const handleSubmit = () => {
        let data = {
            'files': []
        }
        const form_data = new FormData();
        Array.from(selectedFiles).forEach(file => {
            form_data.append(file.name, file);
        });
        data['files'] = Array.from(selectedFiles).forEach(file => {
            return file.name;
        });
        form_data.append('payload', JSON.stringify(data));
        api.post(`/pos/orders/upload/`, form_data).then((res) => {
        }).catch((error) => {
            console.log(error);
            showErrorMessage(String(error));
        })
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }}
        >
            <Grid
                container
                direction={'column'}
                rowSpacing={2}
                alignItems="center"
            >
                <Grid item>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Upload Orders.
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        sx={{
                            margin: '10px'
                        }}
                    >
                        Upload files
                        <VisuallyHiddenInput
                            type="file"
                            onChange={(event) => {
                                console.log(event.target.files);
                                setSelectedFiles(event.target.files);
                            }}
                            multiple
                        />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        sx={{
                            margin: '10px'
                        }}
                        disabled={selectedFiles===null}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
                {selectedFiles !== null ? (
                    <Grid item>
                        {console.log(selectedFiles)}
                        {Array.from(selectedFiles).map((item, index) => {
                            return (
                                <Alert severity="success">{item.name}</Alert>
                            )
                        })}
                    </Grid>
                ) : <></>}
            </Grid>
        </Box>
    )
}

function ViewOrders(props) {
    const { showErrorMessage, showSuccessMessage } = props;
    const username = UserProfile.getName();
    const [showOrderUploadModel, setOrderUploadModel] = useState(false);
    const navigate = useNavigate();
    return (
        <AccessController>
            <Modal
                open={showOrderUploadModel}
                onClose={(e) => {
                    setOrderUploadModel(false)

                }}
            >
                <>
                    <OrderUploadForm 
                        showErrorMessage={showErrorMessage}
                        showSuccessMessage={showSuccessMessage}
                    />
                </>
            </Modal>
            <Grid
                container
                direction={'column'}
                rowSpacing={2}
                alignItems="center"
            >
                <h2>DIVASH | ADMIN</h2>
                <Grid item>
                    <i>Welcome {username}</i>
                </Grid>
                <Grid item>
                    <i>Order History</i>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        sx={{ minWidth: '100px' }}
                        onClick={(e) => {
                            setOrderUploadModel(true)
                        }}
                    >
                        Add Orders
                    </Button>
                </Grid>
            </Grid>
        </AccessController>
    )
}

export default ViewOrders;