import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { LineChart } from '@mui/x-charts/LineChart';


import api from '../../utils/webcall';
import { formatDate } from '../../utils/utils';
import UserProfile from '../../components/User';
import AccessController from '../../components/access_controller';
import { GreenTickIcon, RedCrossIcon } from '../../components/commons/icons';

const uData = [4000, 3000, 2000, 2780, 1890, 2390];
const pData = [2400, 1398, 9800, 3908, 4800, 3800];
const xLabels = [
    'Page A',
    'Page B',
    'Page C',
    'Page D',
    'Page E',
    'Page F',
];


function ViewInventoryDetails(props) {
    const { showErrorMessage, showSuccessMessage } = props;
    const [inventoryData, setInvetoryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [graphData, setGraphData] = useState(null);
    const [graphLabels, setGraphLabels] = useState(null);
    const [disableButton, setDisableButton] = useState(false);
    const [deletingModal, setDeletingModal] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const username = UserProfile.getName();
    let inventory_id = window.location.pathname.split("/");
    inventory_id = inventory_id[inventory_id.length - 1];

    const hasError = (item, index, arr) => {
        if (index + 1 == arr.length) {
            return false;
        }
        if (inventoryData['state_updates'][arr[index]]['type'] === 'update') {
            if (inventoryData['state_updates'][arr[index]]['current_inventory'] > inventoryData['state_updates'][arr[index + 1]]['current_inventory']) {
                return true;
            }
        }
        return false;
    }

    const loadInventryDetails = () => {
        setLoading(true);
        setDisableButton(true);
        api.get(`/pos/inventory/view/${inventory_id}`).then((res) => {
            let data = res.data;
            data['inventory_details']['state_updates'] = JSON.parse(data['inventory_details']['state_updates'])
            setInvetoryData(data['inventory_details']);
        }).catch((error) => {
            console.log(error);
            showErrorMessage(error.message);
        }).finally(() => {
            setLoading(false);
            setDisableButton(false);
        });
    }

    useEffect(() => {
        let gdata = {};
        if (inventoryData) {
            for (const [key, value] of Object.entries(inventoryData['state_updates'])) {
                let d = new Date(value['update_time']);
                gdata[formatDate(d)] = value['inventory_value'];
            }
        }
        let temp = Object.keys(gdata);
        setGraphLabels([...temp]);
        temp = Object.values(gdata);
        setGraphData(temp);
    }, [inventoryData])


    useEffect(() => {
        loadInventryDetails();
    }, []);

    return (
        <AccessController>
            <Modal
                open={deletingModal}
                onClose={(e) => { }}
            >
                <CircularProgress />
            </Modal>

            <Grid
                container
                direction={'column'}
                rowSpacing={2}
                alignItems="center"
            >
                <Grid item>
                    <h2>DIVASH | ADMIN</h2>
                </Grid>
                <Grid item>
                    <i>Welcome {username}</i>.
                </Grid>
                <Grid item>
                    <b>View Inventory Details.</b>
                </Grid>

                {loading ? <Grid item><CircularProgress /></Grid> : <></>}
                {!loading ? (
                    <>
                        <Grid item>
                            <TableContainer
                                component={Paper}
                                style={{
                                    overflowX: "initial"
                                }}
                                key="inventory-table-container"
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Item Name</TableCell>
                                            <TableCell align="center">Current Inventory</TableCell>
                                            <TableCell align="center">Last Inventory Date</TableCell>
                                            <TableCell align="center">Is Perishable</TableCell>
                                            <TableCell align="center">Prepared In-House</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {inventoryData !== null ? (<TableRow>
                                            <TableCell align="center">{inventoryData['item_name']}</TableCell>
                                            <TableCell align="center">{inventoryData['current_inventory']} {inventoryData['measurement_unit']}</TableCell>
                                            <TableCell align="center">{inventoryData['last_inventory_date']}</TableCell>
                                            <TableCell align="center">{inventoryData['is_perishable'] ? <GreenTickIcon /> : <RedCrossIcon />}</TableCell>
                                            <TableCell align="center">{inventoryData['is_prepared_in_house'] ? <GreenTickIcon /> : <RedCrossIcon />}</TableCell>
                                        </TableRow>) : <></>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {graphData !== null ?
                            <Grid item>
                                <LineChart
                                    width={500}
                                    height={300}
                                    series={[
                                        { data: graphData, label: inventoryData['item_name'] },
                                    ]}
                                    xAxis={[{ scaleType: 'point', data: graphLabels }]}
                                />
                            </Grid> : <></>
                        }
                        <Grid
                            item
                            sx={{
                                margin: '20px'
                            }}
                        >
                            <b><i>Inventory Update History</i></b>
                        </Grid>
                        <Grid item>
                            <TableContainer
                                component={Paper}
                                style={{
                                    overflowX: "initial"
                                }}
                                key="inventory-table-container"
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Update Time</TableCell>
                                            <TableCell align="center">Inventory</TableCell>
                                            <TableCell align="center">Update Type</TableCell>
                                            <TableCell align="center">Value</TableCell>
                                            <TableCell align="center">User</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {inventoryData !== null ? (<>
                                            {Object.keys(inventoryData['state_updates']).toReversed().map((item, index, arr) => {
                                                return (
                                                    <TableRow
                                                        key={`inventory-details-row-${index}`}
                                                        style={hasError(item, index, arr) ? { backgroundColor: "#FF0000" } : {}}
                                                    >
                                                        <TableCell align="center">{inventoryData['state_updates'][item]['update_time']}</TableCell>
                                                        <TableCell align="center">{inventoryData['state_updates'][item]['current_inventory']} {inventoryData['state_updates'][item]['measurement_unit']}</TableCell>
                                                        <TableCell align="center">{inventoryData['state_updates'][item]['type']}</TableCell>
                                                        <TableCell align="center">{inventoryData['state_updates'][item]['value']} {inventoryData['state_updates'][item]['measurement_unit']}</TableCell>
                                                        <TableCell align="center">{inventoryData['state_updates'][item]['user']}</TableCell>
                                                    </TableRow>
                                                );
                                            })}</>) : <></>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        
                    </>
                ) : <></>}

            </Grid>
        </AccessController >
    )
}

export default ViewInventoryDetails;