import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";

import api from '../../utils/webcall';
import { VisuallyHiddenInput } from '../../utils/utils';
import UserProfile from '../../components/User';
import AccessController from '../../components/access_controller';


function BillDataTable(props) {
    const { data, update } = props;

    if (data.length == 0) {
        return <></>
    }
    return (<>
        {data.map((item, index) => {
            return (
                <TableRow
                    key={`bill-data-row-${index}`}
                >
                    <TableCell align="center">
                        <OutlinedInput
                            id={`outlined-adornment-bill-row-${index}`}
                            value={item['item_name']}
                            onChange={(e) => {
                                update(index, e.target.value, 'item_name');
                            }}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <OutlinedInput
                            id={`outlined-adornment-bill-row-${index}`}
                            value={item['quantity']}
                            onChange={(e) => {
                                update(index, e.target.value, 'quantity');
                            }}
                        />
                    </TableCell>
                    <TableCell align="center">
                        <Select
                            key={`bill-row-${index}-`}
                            value={item['measurement_unit']}
                            label="Age"
                            onChange={(e) => {
                                update(index, e.target.value, 'measurement_unit');
                            }}
                        >
                            <MenuItem value={"Grams"}>Grams</MenuItem>
                            <MenuItem value={"Kilograms"}>Kilograms</MenuItem>
                            <MenuItem value={"Milliliters"}>Milliliters</MenuItem>
                            <MenuItem value={"Liters"}>Liters</MenuItem>
                            <MenuItem value={"Centimetres"}>Centimeters</MenuItem>
                            <MenuItem value={"Meters"}>Meters</MenuItem>
                        </Select>
                    </TableCell>
                    <TableCell align="center">
                        <OutlinedInput
                            id={`outlined-adornment-bill-row-${index}`}
                            value={item['total_price']}
                            onChange={(e) => {
                                update(index, e.target.value, 'total_price');
                            }}
                        />
                    </TableCell>
                    <TableCell align="center">{parseFloat(item['total_price']) / parseFloat(item['quantity'])}</TableCell>
                </TableRow>
            )
        })}
    </>)
}


function AddBill(props) {
    const { showErrorMessage, showSuccessMessage } = props;
    const [billData, setBillData] = useState([]);
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const username = UserProfile.getName();

    const update = (index, value, target) => {
        let temp = billData;
        temp[index][target] = value;
        setBillData([...temp]);
    }

    

    const handleSubmit = () => {
        let temp = billData;
        let i;
        for (i = 0; i < temp.length; i++) {
            if (temp[i]['measurement_unit'] === 'Kilograms') {
                temp[i]['quantity'] *= 1000;
                temp[i]['measurement_unit'] = 'Grams';
            }
            else if (temp[i]['measurement_unit'] === 'Liters') {
                temp[i]['quantity'] *= 1000;
                temp[i]['measurement_unit'] = 'Milliliters';
            }
            else if (temp[i]['measurement_unit'] === 'Meters') {
                temp[i]['quantity'] *= 100;
                temp[i]['measurement_unit'] = 'Centimeters';
            }
        }
        let data = {
            'data': temp,
            'files': []
        }
        const form_data = new FormData();
        Array.from(selectedFile).forEach(file => {
            form_data.append(file.name, file);
        });
        data['files'] = Array.from(selectedFile).forEach(file => {
            return file.name;
        });
        form_data.append('payload', JSON.stringify(data));
        api.post(`/pos/bill/add/`, form_data).then((res) => {
            let data = res.data;
            let bill_id = data['id']
            navigate(`/bill/view/${bill_id}`)
        }).catch((error) => {
            console.log(error);
            showErrorMessage(String(error));
        })

    }

    const addNewRow = () => {
        let temp = billData;
        temp.push({
            'item_name': '',
            'quantity': '',
            'measurement_unit': 'Grams',
            'total_price': ''
        })
        setBillData([...temp]);
    }

    return (
        <AccessController
            onlyAdmin={false}
        >
            <Grid
                container
                direction={'column'}
                rowSpacing={2}
                alignItems="center"
            >
                <h2>DIVASH | ADMIN</h2>
                <Grid item>
                    <i>Welcome {username}</i>. <br />
                    <b>Submit New Bill.</b>
                </Grid>
                <Grid item>
                    <TableContainer
                        component={Paper}
                        style={{
                            overflowX: "initial"
                        }}
                        key="inventory-table-container"
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Item Name</TableCell>
                                    <TableCell align="center">Quantity</TableCell>
                                    <TableCell align="center">Measurement Unit</TableCell>
                                    <TableCell align="center">Net Price</TableCell>
                                    <TableCell align="center">Price per Unit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <BillDataTable data={billData} update={update} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        sx={{
                            margin: '10px'
                        }}
                    >
                        Upload files
                        <VisuallyHiddenInput
                            type="file"
                            onChange={(event) => {
                                console.log(event.target.files);
                                setSelectedFile(event.target.files);
                            }}
                            multiple
                        />
                    </Button>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            addNewRow()
                        }}
                        sx={{
                            margin: '10px'
                        }}
                    >
                        Add New Row
                    </Button>
                </Grid>

                {selectedFile === null ? (
                    <Grid item>
                        <Alert severity="error">Select Bill Image to Upload</Alert>
                    </Grid>
                ) : <></>}
                {selectedFile !== null ? (
                    <Grid item>
                        {console.log(selectedFile)}
                        {Array.from(selectedFile).map((item, index) => {
                            return (
                                <Alert severity="success">{item.name}</Alert>
                            )
                        })}
                    </Grid>
                ) : <></>}

                <Grid item>

                    <Button
                        variant="outlined"
                        sx={{
                            margin: '10px'
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit()
                        }}
                        disabled={selectedFile == null}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </AccessController >
    )
}

export default AddBill;