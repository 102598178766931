import axios from 'axios';
import { ACCESS_TOKEN } from './token';
import UserProfile from '../components/User';
import { config } from '../config';

const apiUrl = config.url.API_ENDPOINT;

console.log(import.meta);

// const baseURL = import.meta.env.REACT_APP_API_ENDPOINT ? import.meta.env.REACT_APP_API_ENDPOINT : apiUrl;
const baseURL = apiUrl;

const api = axios.create({
    baseURL: baseURL
})

api.interceptors.request.use(
    (config) => {
        const accessToken = window.sessionStorage.getItem(ACCESS_TOKEN);
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);


api.interceptors.request.use(request => {
    if(process.env.NODE_ENV === 'development'){
        // console.log('Starting Request', request);
    }
    return request;
})

api.interceptors.response.use(response => {
    if (process.env.NODE_ENV === 'development') {
        // console.log('Response:', response);
    }
    if(response.status && response.status == 401){
        UserProfile.logout();
        
    }
    return response;
})



export default api;