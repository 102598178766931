import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import UserProfile from '../../components/User';
import AccessController from '../../components/access_controller';

function StyledGrid(props) {
    const { children } = props;
    return (
        <Grid
            sx={{
                margin: '10px'
            }}
            item
        >
            {children}
        </Grid>
    )
}

function HomePage(props) {
    const { showErrorMessage, showSuccessMessage } = props;

    const navigate = useNavigate();
    const username = UserProfile.getName();
    const buttonStyle = {
        minWidth:'145px'
    }

    return (
        <AccessController
            onlyAdmin={false}
        >
            <Grid
                container
                direction={'column'}
                alignItems="center"
                rowSpacing={1}
            >
                <Grid
                    sx={{
                        margin: '10px',
                        textAlign: 'center'
                    }}
                    item
                >
                    <h2>Store Management</h2>
                    <i>Welcome {username}</i>
                </Grid>
                <Grid
                    container
                    direction={'row'}
                    alignItems="center"
                    rowSpacing={1}
                >
                    <Grid container item xs={6} direction={'column'} alignItems={'center'}>
                        <StyledGrid>
                            <Button variant="contained" sx={buttonStyle} onClick={(e) => {
                                navigate("/inventory/add/status");
                            }}>
                                Fill<br/> Inventory
                            </Button>
                        </StyledGrid>
                        <StyledGrid>
                            <Button variant="contained" sx={buttonStyle} onClick={(e) => {
                                navigate("/inventory/add/preparation")
                            }}>
                                Add<br/> Preparations
                            </Button>
                        </StyledGrid>
                        <StyledGrid>
                            <Button
                                variant="contained"
                                sx={buttonStyle}
                                color="error"
                                onClick={(e) => {
                                    navigate("/inventory/add/usage/")
                                }}
                            >
                                Add<br/> Usage
                            </Button>
                        </StyledGrid>
                        <StyledGrid>
                            <Button
                                variant="contained"
                                sx={buttonStyle}
                                onClick={(e) => {
                                    navigate("/orders/view")
                                }}
                                color="secondary"
                            >
                                View<br/> Orders
                            </Button>
                        </StyledGrid>
                    </Grid>
                    <Grid container item xs={6} direction={'column'} alignItems={'center'}>
                        <StyledGrid>
                            <Button variant="contained" sx={buttonStyle} onClick={(e) => {
                                navigate("/bill/add/")
                            }}>
                                Add<br/> Bill
                            </Button>
                        </StyledGrid>
                        <StyledGrid>
                            <Button
                                variant="contained"
                                sx={buttonStyle}
                                color="error"
                                onClick={(e) => {
                                    navigate("/inventory/add/wastage/")
                                }}
                            >
                                Add<br/> Wastage
                            </Button>
                        </StyledGrid>
                        <StyledGrid>
                            <Button variant="contained" sx={buttonStyle} onClick={(e) => {
                                navigate("/bill/view")
                            }}>
                                View<br /> Bills
                            </Button>
                        </StyledGrid>
                        {UserProfile.isManager() ? (<StyledGrid>
                            <Button variant="contained" sx={buttonStyle} onClick={(e) => {
                                navigate("/inventory/view")
                            }}>
                                View<br /> Inventory
                            </Button>
                        </StyledGrid>) : <></>}
                    </Grid>
                </Grid>
            </Grid>
        </AccessController >
    )
}

export default HomePage;